import Vue from 'vue'
import VueFlex from 'vue-flex';
import 'vue-flex/dist/vue-flex.css';
import VTooltip from 'v-tooltip';
import App from './App.vue'
import VueAnalytics from "vue-analytics";

Vue.config.productionTip = false
Vue.use(VueFlex)
Vue.use(VTooltip)
Vue.use(VueAnalytics, {
  id: 'UA-82071471-2'
})

new Vue({
  render: h => h(App),
}).$mount('#app')
