<template>
  <flex-col class="contact-bar pt-2 pb-5" align-h="center" align-v="between" fill>
    <div class="logo-icon" @click="goToBlock('Welcome')">
      <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8455 0.000488281C18.8688 0.000488281 19.6983 0.829992 19.6983 1.85324V34.0596C19.6983 35.0829 18.8688 35.9124 17.8455 35.9124C16.8223 35.9124 15.9928 35.0829 15.9928 34.0596V1.85324C15.9928 0.829992 16.8223 0.000488281 17.8455 0.000488281Z" fill="#ED6060" fill-opacity="0.3"/>
        <path d="M17.8369 6.03516C18.3283 6.03516 18.7995 6.23035 19.1469 6.57781C19.4944 6.92527 19.6896 7.39652 19.6896 7.88791C19.6896 8.37929 19.4944 8.85054 19.1469 9.198C18.7995 9.54545 18.3283 9.74065 17.8369 9.74065C17.3455 9.74065 16.8742 9.54545 16.5267 9.198C16.1794 8.85054 15.9841 8.37929 15.9841 7.88791C15.9841 7.39652 16.1794 6.92527 16.5267 6.57781C16.8742 6.23035 17.3455 6.03516 17.8369 6.03516Z" fill="black"/>
        <path d="M17.8369 29.8433C18.3283 29.8433 18.7995 30.0385 19.1469 30.386C19.4944 30.7334 19.6896 31.2046 19.6896 31.696C19.6896 32.1874 19.4944 32.6587 19.1469 33.0061C18.7995 33.3535 18.3283 33.5488 17.8369 33.5488C17.3455 33.5488 16.8742 33.3535 16.5267 33.0061C16.1794 32.6587 15.9841 32.1874 15.9841 31.696C15.9841 31.2046 16.1794 30.7334 16.5267 30.386C16.8742 30.0385 17.3455 29.8433 17.8369 29.8433Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.92303 2.39159C9.80919 1.87997 10.9423 2.1836 11.4539 3.06976L27.5571 30.9616C28.0687 31.8478 27.7651 32.9809 26.8789 33.4925C25.9928 34.0041 24.8597 33.7005 24.3481 32.8143L8.24486 4.92249C7.73325 4.03633 8.03687 2.90321 8.92303 2.39159Z" fill="#ED6060" fill-opacity="0.3"/>
        <path d="M8.80201 2.20471C9.01275 2.0828 9.24544 2.0036 9.48679 1.97163C9.72814 1.93965 9.97343 1.95553 10.2086 2.01834C10.4439 2.08116 10.6644 2.18969 10.8577 2.33775C11.051 2.48579 11.2131 2.67046 11.3351 2.88121C11.457 3.09194 11.5362 3.32464 11.5681 3.56598C11.6001 3.80733 11.5842 4.05262 11.5214 4.28784C11.4586 4.52305 11.3501 4.74359 11.2021 4.93686C11.054 5.13013 10.8693 5.29235 10.6586 5.41426C10.233 5.65944 9.72737 5.72551 9.25305 5.59792C8.77872 5.47035 8.37451 5.15956 8.12932 4.73396C7.88414 4.30834 7.81807 3.80276 7.94565 3.32843C8.07323 2.8541 8.38402 2.44989 8.80962 2.20471H8.80201Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.39135 8.92327C2.90297 8.03711 4.03609 7.73349 4.92225 8.24511L32.8141 24.3483C33.7002 24.8599 34.0039 25.993 33.4922 26.8792C32.9806 27.7654 31.8475 28.069 30.9613 27.5574L3.06951 11.4542C2.18335 10.9426 1.87973 9.80943 2.39135 8.92327Z" fill="#ED6060" fill-opacity="0.3"/>
        <path d="M2.19377 8.82158C2.31518 8.61084 2.4769 8.42607 2.66971 8.27783C2.86252 8.12959 3.08263 8.02078 3.3175 7.95761C3.55236 7.89444 3.79737 7.87814 4.03853 7.90965C4.27968 7.94117 4.51228 8.01988 4.72302 8.14128C4.93376 8.26268 5.11853 8.42441 5.26677 8.61722C5.41501 8.81002 5.52383 9.03014 5.58699 9.265C5.65016 9.49987 5.66646 9.74488 5.63495 9.98604C5.60343 10.2272 5.52473 10.4598 5.40332 10.6705C5.15814 11.0961 4.75392 11.407 4.2796 11.5345C3.80527 11.6621 3.29969 11.596 2.87407 11.3508C2.44847 11.1057 2.13768 10.7014 2.01011 10.2271C1.88252 9.75278 1.94859 9.2472 2.19377 8.82158Z" fill="black"/>
        <path d="M30.278 25.0359C30.3994 24.8253 30.5611 24.6406 30.754 24.4925C30.9467 24.3442 31.1668 24.2355 31.4017 24.1724C31.6365 24.1093 31.8814 24.093 32.1225 24.1245C32.3635 24.1561 32.5961 24.2348 32.8067 24.3562C33.0174 24.4776 33.2021 24.6393 33.3503 24.8321C33.4985 25.0248 33.6072 25.245 33.6703 25.4798C33.7335 25.7146 33.7497 25.9595 33.7182 26.2007C33.6866 26.4417 33.6079 26.6742 33.4865 26.8849C33.2414 27.3104 32.8372 27.621 32.363 27.7484C31.8887 27.8759 31.3833 27.8098 30.9578 27.5646C30.5323 27.3195 30.2217 26.9153 30.0942 26.4411C29.9668 25.9668 30.0329 25.4614 30.278 25.0359Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.000488281 17.8454C0.000488281 16.8222 0.829992 15.9927 1.85324 15.9927H34.0596C35.0829 15.9927 35.9124 16.8222 35.9124 17.8454C35.9124 18.8687 35.0829 19.6982 34.0596 19.6982H1.85324C0.829992 19.6982 0.000488281 18.8687 0.000488281 17.8454Z" fill="#ED6060" fill-opacity="0.3"/>
        <path d="M32.2075 17.8567C32.2075 17.3653 32.4027 16.894 32.7501 16.5465C33.0976 16.1991 33.5689 16.0039 34.0603 16.0039C34.5517 16.0039 35.0229 16.1991 35.3703 16.5465C35.7178 16.894 35.913 17.3653 35.913 17.8567C35.913 18.348 35.7178 18.8193 35.3703 19.1668C35.0229 19.5142 34.5517 19.7094 34.0603 19.7094C33.5689 19.7094 33.0976 19.5142 32.7501 19.1668C32.4027 18.8193 32.2075 18.348 32.2075 17.8567Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M33.4922 8.8119C34.0039 9.69804 33.7003 10.8312 32.8141 11.3428L4.9223 27.4471C4.03615 27.9587 2.90302 27.6552 2.39138 26.769C1.87973 25.8829 2.18332 24.7497 3.06947 24.2381L30.9613 8.13381C31.8474 7.62216 32.9806 7.92575 33.4922 8.8119Z" fill="#ED6060" fill-opacity="0.3"/>
        <path d="M2.20464 26.8893C2.08324 26.6785 2.00453 26.4459 1.97301 26.2048C1.9415 25.9636 1.95779 25.7187 2.02096 25.4837C2.08414 25.2489 2.19294 25.0288 2.34119 24.836C2.48943 24.6431 2.67419 24.4814 2.88494 24.36C3.09567 24.2386 3.32827 24.1599 3.56943 24.1283C3.81058 24.0969 4.05559 24.1132 4.29045 24.1763C4.52532 24.2395 4.74544 24.3484 4.93824 24.4966C5.13106 24.6449 5.29278 24.8295 5.41418 25.0403C5.65936 25.4659 5.72544 25.9715 5.59786 26.4458C5.47027 26.9201 5.1595 27.3244 4.73388 27.5696C4.30827 27.8147 3.80269 27.8808 3.32836 27.7533C2.85404 27.6257 2.44982 27.3148 2.20464 26.8893Z" fill="black"/>
        <path d="M25.7416 13.1676C25.4965 12.742 25.4304 12.2364 25.5579 11.7621C25.6855 11.2878 25.9964 10.8835 26.4219 10.6384C26.8475 10.3932 27.3532 10.3271 27.8275 10.4547C28.3018 10.5823 28.706 10.8931 28.9512 11.3187C29.0726 11.5294 29.1513 11.762 29.1828 12.0032C29.2143 12.2443 29.1981 12.4894 29.1349 12.7242C29.0717 12.959 28.9628 13.1791 28.8146 13.372C28.6663 13.5648 28.4817 13.7265 28.2709 13.8479C28.0601 13.9693 27.8276 14.048 27.5863 14.0796C27.3452 14.111 27.1002 14.0948 26.8654 14.0316C26.6305 13.9684 26.4104 13.8595 26.2176 13.7113C26.0247 13.5631 25.863 13.3784 25.7416 13.1676Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M26.8789 2.19871C27.7651 2.71031 28.0687 3.84343 27.5571 4.72959L11.4539 32.6225C10.9423 33.5087 9.80922 33.8123 8.92306 33.3007C8.03689 32.7891 7.73325 31.656 8.24485 30.7698L24.348 2.87691C24.8596 1.99075 25.9928 1.6871 26.8789 2.19871Z" fill="#ED6060" fill-opacity="0.3"/>
        <path d="M8.8217 33.4983C8.61097 33.3769 8.4262 33.2152 8.27796 33.0224C8.12972 32.8295 8.0209 32.6094 7.95773 32.3746C7.89456 32.1398 7.87826 31.8947 7.90978 31.6536C7.9413 31.4124 8.02 31.1798 8.1414 30.969C8.26281 30.7583 8.42453 30.5736 8.61734 30.4253C8.81015 30.2771 9.03027 30.1682 9.26513 30.105C9.49999 30.0419 9.745 30.0256 9.98615 30.0571C10.2273 30.0886 10.4599 30.1674 10.6706 30.2887C11.0963 30.5339 11.407 30.9382 11.5346 31.4125C11.6622 31.8868 11.5962 32.3924 11.3509 32.818C11.1058 33.2436 10.7016 33.5544 10.2272 33.682C9.75289 33.8096 9.24732 33.7434 8.8217 33.4983Z" fill="black"/>
        <path d="M25.0357 5.4143C24.825 5.2929 24.6402 5.13117 24.4919 4.93837C24.3437 4.74556 24.2349 4.52544 24.1717 4.29058C24.1086 4.05572 24.0922 3.81071 24.1238 3.56956C24.1553 3.32839 24.2341 3.0958 24.3554 2.88506C24.4768 2.67432 24.6385 2.48956 24.8314 2.34132C25.0242 2.19307 25.2442 2.08426 25.4792 2.02109C25.714 1.95791 25.959 1.94162 26.2001 1.97314C26.4414 2.00464 26.6739 2.08336 26.8847 2.20475C27.3103 2.44995 27.6211 2.85416 27.7487 3.32849C27.8763 3.80281 27.8101 4.3084 27.565 4.734C27.3198 5.15962 26.9156 5.4704 26.4413 5.59798C25.967 5.72555 25.4613 5.65949 25.0357 5.4143Z" fill="black"/>
        <path d="M18.7635 19.6183C19.1891 19.3732 19.6947 19.3071 20.169 19.4346C20.6433 19.5622 21.0476 19.8731 21.2927 20.2986C21.5379 20.7242 21.604 21.2299 21.4764 21.7042C21.3488 22.1785 21.038 22.5827 20.6124 22.8279C20.4016 22.9493 20.1691 23.028 19.9279 23.0595C19.6868 23.091 19.4417 23.0748 19.2069 23.0116C18.9721 22.9484 18.752 22.8395 18.5591 22.6913C18.3663 22.543 18.2046 22.3584 18.0832 22.1476C17.9618 21.9368 17.8831 21.7043 17.8515 21.463C17.8201 21.2219 17.8363 20.9769 17.8995 20.7421C18.0271 20.2678 18.3379 19.8635 18.7635 19.6183Z" fill="black"/>
        <path d="M9.7395 17.8567C9.7395 17.3653 9.93471 16.894 10.2822 16.5465C10.6296 16.1991 11.1009 16.0039 11.5923 16.0039C12.0837 16.0039 12.5549 16.1991 12.9023 16.5465C13.2498 16.894 13.445 17.3653 13.445 17.8567C13.445 18.348 13.2498 18.8193 12.9023 19.1668C12.5549 19.5142 12.0837 19.7094 11.5923 19.7094C11.1009 19.7094 10.6296 19.5142 10.2822 19.1668C9.93471 18.8193 9.7395 18.348 9.7395 17.8567Z" fill="black"/>
      </svg>
    </div>
    <flex-col class="progress" align-h="center" align-v="start" fill>
      <div class="progress-bar" id="progressBar"></div>
    </flex-col>
    <flex-col class="links" align-h="center">
      <div class="text-sideways py-2 link" @click="goToBlock('Contacts')">contact us</div>
      <div class="pb-3 link" @click="openExternal('https://t.me/firmshift_cryptodev')">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.7767 2.47788L1.95669 7.03588C1.15002 7.35988 1.15469 7.80988 1.80869 8.01055L4.84336 8.95721L11.8647 4.52721C12.1967 4.32521 12.5 4.43388 12.2507 4.65521L6.56202 9.78921H6.56069L6.56202 9.78988L6.35269 12.9179C6.65936 12.9179 6.79469 12.7772 6.96669 12.6112L8.44069 11.1779L11.5067 13.4425C12.072 13.7539 12.478 13.5939 12.6187 12.9192L14.6314 3.43388C14.8374 2.60788 14.316 2.23388 13.7767 2.47788Z" fill="#ED6060"/>
        </svg>
      </div>
      <div class="pb-3 link" @click="openExternal('https://www.linkedin.com/groups/13949610/')">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.134 14H1.2315V4.653H4.134V14ZM2.681 3.378C1.753 3.378 1 2.609 1 1.681C1 1.23517 1.1771 0.807602 1.49235 0.492353C1.8076 0.177105 2.23517 0 2.681 0C3.12683 0 3.5544 0.177105 3.86965 0.492353C4.1849 0.807602 4.362 1.23517 4.362 1.681C4.362 2.609 3.609 3.378 2.681 3.378ZM14.997 14H12.101V9.45C12.101 8.3655 12.079 6.975 10.592 6.975C9.083 6.975 8.8515 8.153 8.8515 9.372V14H5.952V4.653H8.7355V5.928H8.776C9.1635 5.1935 10.11 4.4185 11.522 4.4185C14.4595 4.4185 14.9995 6.353 14.9995 8.8655V14H14.997Z" fill="#ED6060"/>
        </svg>
      </div>
      <div class="pb-4 link" @click="openExternal('https://discord.gg/gGNYw4SBqx')">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.3333 4.00033C13.3333 4.00033 11.8046 2.80366 9.99996 2.66699L9.83729 2.99266C11.4686 3.39233 12.2173 3.96366 13 4.66699C11.651 3.97833 10.32 3.33366 7.99996 3.33366C5.67996 3.33366 4.34896 3.97833 2.99996 4.66699C3.78263 3.96366 4.67329 3.32833 6.16263 2.99266L5.99996 2.66699C4.10663 2.84533 2.66663 4.00033 2.66663 4.00033C2.66663 4.00033 0.959626 6.47566 0.666626 11.3337C2.38663 13.318 4.99996 13.3337 4.99996 13.3337L5.54663 12.6057C4.61863 12.283 3.57196 11.7073 2.66663 10.667C3.74596 11.4837 5.37496 12.3337 7.99996 12.3337C10.625 12.3337 12.254 11.4837 13.3333 10.667C12.4283 11.7073 11.3816 12.283 10.4533 12.6057L11 13.3337C11 13.3337 13.6133 13.318 15.3333 11.3337C15.0403 6.47566 13.3333 4.00033 13.3333 4.00033ZM5.83329 10.0003C5.18863 10.0003 4.66663 9.40366 4.66663 8.66699C4.66663 7.93032 5.18863 7.33366 5.83329 7.33366C6.47796 7.33366 6.99996 7.93032 6.99996 8.66699C6.99996 9.40366 6.47796 10.0003 5.83329 10.0003ZM10.1666 10.0003C9.52196 10.0003 8.99996 9.40366 8.99996 8.66699C8.99996 7.93032 9.52196 7.33366 10.1666 7.33366C10.8113 7.33366 11.3333 7.93032 11.3333 8.66699C11.3333 9.40366 10.8113 10.0003 10.1666 10.0003Z" fill="#ED6060"/>
        </svg>
      </div>
    </flex-col>
  </flex-col>
</template>

<script>
export default {
  name: "ContactBar",
methods: {
    getBarSize: function () {
      let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      let height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      let scrolled = (winScroll / height) * 100;
      document.getElementById("progressBar").style.height = scrolled + "%";
    },
  openExternal(url) {
    window.open(url, '_blank');
  },
  goToBlock:  function (name) {
    let headerOffset = document.querySelector('.header-mobile') ? document.querySelector('.header-mobile').offsetHeight : 0;
    let block = document.getElementById(name);
    const blockPosition = block.getBoundingClientRect().top - document.body.getBoundingClientRect().top - headerOffset;
    window.scrollTo({top: blockPosition, behavior: 'smooth'});
  },
},
  created () {
    window.addEventListener('scroll', this.getBarSize);
  },
  destroyed () {
    window.removeEventListener('scroll', this.getBarSize);
  },
 }
</script>

<style scoped lang="scss">

.contact-bar {
  position: fixed;
  right: 0;
  top: 0;
  width: 5%;
  height: 100%;
  box-sizing: border-box;
  z-index: 999;
  .logo-icon svg { width: 1.8rem; cursor: pointer}
  .progress {
    width: 4px;
    border-radius: 8px;
    background-color: rgba($secondary, 0.3);
    .progress-bar {
      height: 1%;
      width: 100%;
      background-color: rgba($primary, 0.5);
      border-radius: 8px;
    }
  }
  .links {
    color: $primary;
    opacity: 0.9;
    font-size: max(0.7rem, 14px);
    line-height: 1;
  }
  .link {
    opacity: 0.85;
    &:hover { opacity: 1; }
  }
}

</style>
