<template>
    <section>
        <flex-row class="section-title pt-xs-3 pb-xs-5" align-v="center" id="WhatWeDo">
            what we do
        </flex-row>
        <div class="section-body">
            <flex-row class="section-body-content py-xs-0" align-h="between" align-v="stretch">
                <flex-col class="item w-xs-100">
                    <div>
                        <svg viewBox="0 0 137 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.3">
                                <path d="M12.0159 12.736C10.9919 14.912 10.4799 17.92 10.4799 21.76C10.4799 25.6 10.9919 28.608 12.0159 30.784C13.2959 33.344 15.3226 34.624 18.0959 34.624C20.8693 34.624 22.8959 33.344 24.1759 30.784C25.2426 28.6507 25.7759 25.6427 25.7759 21.76C25.7759 17.8773 25.2426 14.8693 24.1759 12.736C22.8959 10.176 20.8693 8.896 18.0959 8.896C15.3226 8.896 13.2959 10.176 12.0159 12.736ZM4.01592 36.416C1.58392 32.7467 0.36792 27.8613 0.36792 21.76C0.36792 15.6587 1.58392 10.7733 4.01592 7.104C7.13059 2.368 11.8239 0 18.0959 0C24.4106 0 29.1253 2.368 32.2399 7.104C34.6719 10.7733 35.8879 15.6587 35.8879 21.76C35.8879 27.8613 34.6719 32.7467 32.2399 36.416C29.0399 41.152 24.3253 43.52 18.0959 43.52C11.9093 43.52 7.21592 41.152 4.01592 36.416Z" fill="#ED6060"/>
                                <path d="M67.2434 39.744L56.7474 29.184L46.2514 39.744L40.4914 33.856L50.9234 23.36L40.4914 12.8L46.2514 7.04L56.7474 17.6L67.2434 7.04L73.0034 12.8L62.5074 23.36L73.0034 33.856L67.2434 39.744Z" fill="#ED6060"/>
                                <path d="M89.3284 12.736C88.3044 14.912 87.7924 17.92 87.7924 21.76C87.7924 25.6 88.3044 28.608 89.3284 30.784C90.6084 33.344 92.6351 34.624 95.4084 34.624C98.1817 34.624 100.208 33.344 101.488 30.784C102.555 28.6507 103.088 25.6427 103.088 21.76C103.088 17.8773 102.555 14.8693 101.488 12.736C100.208 10.176 98.1817 8.896 95.4084 8.896C92.6351 8.896 90.6084 10.176 89.3284 12.736ZM81.3284 36.416C78.8964 32.7467 77.6804 27.8613 77.6804 21.76C77.6804 15.6587 78.8964 10.7733 81.3284 7.104C84.4431 2.368 89.1364 0 95.4084 0C101.723 0 106.438 2.368 109.552 7.104C111.984 10.7733 113.2 15.6587 113.2 21.76C113.2 27.8613 111.984 32.7467 109.552 36.416C106.352 41.152 101.638 43.52 95.4084 43.52C89.2217 43.52 84.5284 41.152 81.3284 36.416Z" fill="#ED6060"/>
                                <path d="M136.769 42.496H126.721V15.488H116.801V8.768C119.83 8.72534 122.369 7.95733 124.417 6.464C126.508 4.97067 127.745 3.136 128.129 0.959999H136.769V42.496Z" fill="#ED6060"/>
                            </g>
                        </svg>
                    </div>
                    <div class="h4 text-primary mt-4 mb-1">
                        Smart contracts<br>development
                    </div>
                    <div>
                        We have profound experience in developing smart contracts for Ethereum, Corda, Hyperledger. Our projects include decentralized finance (DeFi), supply chain automation, KYC data exchange.
                    </div>

                </flex-col>
                <flex-col class="item w-xs-100">
                    <div>
                        <svg viewBox="0 0 149 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.3">
                                <path d="M12.0159 12.736C10.9919 14.912 10.4799 17.92 10.4799 21.76C10.4799 25.6 10.9919 28.608 12.0159 30.784C13.2959 33.344 15.3226 34.624 18.0959 34.624C20.8693 34.624 22.8959 33.344 24.1759 30.784C25.2426 28.6507 25.7759 25.6427 25.7759 21.76C25.7759 17.8773 25.2426 14.8693 24.1759 12.736C22.8959 10.176 20.8693 8.896 18.0959 8.896C15.3226 8.896 13.2959 10.176 12.0159 12.736ZM4.01592 36.416C1.58392 32.7467 0.36792 27.8613 0.36792 21.76C0.36792 15.6587 1.58392 10.7733 4.01592 7.104C7.13059 2.368 11.8239 0 18.0959 0C24.4106 0 29.1253 2.368 32.2399 7.104C34.6719 10.7733 35.8879 15.6587 35.8879 21.76C35.8879 27.8613 34.6719 32.7467 32.2399 36.416C29.0399 41.152 24.3253 43.52 18.0959 43.52C11.9093 43.52 7.21592 41.152 4.01592 36.416Z" fill="#ED6060"/>
                                <path d="M67.2434 39.744L56.7474 29.184L46.2514 39.744L40.4914 33.856L50.9234 23.36L40.4914 12.8L46.2514 7.04L56.7474 17.6L67.2434 7.04L73.0034 12.8L62.5074 23.36L73.0034 33.856L67.2434 39.744Z" fill="#ED6060"/>
                                <path d="M89.3284 12.736C88.3044 14.912 87.7924 17.92 87.7924 21.76C87.7924 25.6 88.3044 28.608 89.3284 30.784C90.6084 33.344 92.6351 34.624 95.4084 34.624C98.1817 34.624 100.208 33.344 101.488 30.784C102.555 28.6507 103.088 25.6427 103.088 21.76C103.088 17.8773 102.555 14.8693 101.488 12.736C100.208 10.176 98.1817 8.896 95.4084 8.896C92.6351 8.896 90.6084 10.176 89.3284 12.736ZM81.3284 36.416C78.8964 32.7467 77.6804 27.8613 77.6804 21.76C77.6804 15.6587 78.8964 10.7733 81.3284 7.104C84.4431 2.368 89.1364 0 95.4084 0C101.723 0 106.438 2.368 109.552 7.104C111.984 10.7733 113.2 15.6587 113.2 21.76C113.2 27.8613 111.984 32.7467 109.552 36.416C106.352 41.152 101.638 43.52 95.4084 43.52C89.2217 43.52 84.5284 41.152 81.3284 36.416Z" fill="#ED6060"/>
                                <path d="M127.283 17.6L117.811 17.536C117.555 16.8533 117.427 15.7013 117.427 14.08C117.427 10.1547 118.814 6.82667 121.587 4.096C124.403 1.36533 128.201 0 132.979 0C137.63 0 141.363 1.32267 144.179 3.968C146.995 6.57067 148.403 9.792 148.403 13.632C148.403 19.008 145.673 23.3173 140.211 26.56L132.339 31.232C130.931 32.128 129.993 33.0667 129.523 34.048H148.723V42.496H117.171C117.171 38.4 117.897 34.8587 119.347 31.872C120.841 28.8427 123.379 26.2827 126.963 24.192L133.747 20.224C135.411 19.2427 136.585 18.2827 137.267 17.344C137.95 16.3627 138.291 15.1893 138.291 13.824C138.291 12.416 137.801 11.2427 136.819 10.304C135.881 9.36533 134.515 8.896 132.723 8.896C130.846 8.896 129.395 9.472 128.371 10.624C127.39 11.776 126.899 13.248 126.899 15.04C126.899 15.936 127.027 16.7893 127.283 17.6Z" fill="#ED6060"/>
                            </g>
                        </svg>
                    </div>
                    <div class="h4 text-primary mt-4 mb-1">
                        Full stack DApp<br>development
                    </div>
                    <div>
                        We can offer end-to-end development services for your DApp: web and mobile UI, interactions with smart contracts, back-end services and APIs, integration with crypto wallets, aggregators, exchanges.
                    </div>
                </flex-col>
                <flex-col class="item w-xs-100">
                    <div>
                        <svg viewBox="0 0 149 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.3">
                                <path d="M12.0159 12.736C10.9919 14.912 10.4799 17.92 10.4799 21.76C10.4799 25.6 10.9919 28.608 12.0159 30.784C13.2959 33.344 15.3226 34.624 18.0959 34.624C20.8693 34.624 22.8959 33.344 24.1759 30.784C25.2426 28.6507 25.7759 25.6427 25.7759 21.76C25.7759 17.8773 25.2426 14.8693 24.1759 12.736C22.8959 10.176 20.8693 8.896 18.0959 8.896C15.3226 8.896 13.2959 10.176 12.0159 12.736ZM4.01592 36.416C1.58392 32.7467 0.36792 27.8613 0.36792 21.76C0.36792 15.6587 1.58392 10.7733 4.01592 7.104C7.13059 2.368 11.8239 0 18.0959 0C24.4106 0 29.1253 2.368 32.2399 7.104C34.6719 10.7733 35.8879 15.6587 35.8879 21.76C35.8879 27.8613 34.6719 32.7467 32.2399 36.416C29.0399 41.152 24.3253 43.52 18.0959 43.52C11.9093 43.52 7.21592 41.152 4.01592 36.416Z" fill="#ED6060"/>
                                <path d="M67.2434 39.744L56.7474 29.184L46.2514 39.744L40.4914 33.856L50.9234 23.36L40.4914 12.8L46.2514 7.04L56.7474 17.6L67.2434 7.04L73.0034 12.8L62.5074 23.36L73.0034 33.856L67.2434 39.744Z" fill="#ED6060"/>
                                <path d="M89.3284 12.736C88.3044 14.912 87.7924 17.92 87.7924 21.76C87.7924 25.6 88.3044 28.608 89.3284 30.784C90.6084 33.344 92.6351 34.624 95.4084 34.624C98.1817 34.624 100.208 33.344 101.488 30.784C102.555 28.6507 103.088 25.6427 103.088 21.76C103.088 17.8773 102.555 14.8693 101.488 12.736C100.208 10.176 98.1817 8.896 95.4084 8.896C92.6351 8.896 90.6084 10.176 89.3284 12.736ZM81.3284 36.416C78.8964 32.7467 77.6804 27.8613 77.6804 21.76C77.6804 15.6587 78.8964 10.7733 81.3284 7.104C84.4431 2.368 89.1364 0 95.4084 0C101.723 0 106.438 2.368 109.552 7.104C111.984 10.7733 113.2 15.6587 113.2 21.76C113.2 27.8613 111.984 32.7467 109.552 36.416C106.352 41.152 101.638 43.52 95.4084 43.52C89.2217 43.52 84.5284 41.152 81.3284 36.416Z" fill="#ED6060"/>
                                <path d="M128.813 24.448L124.461 17.792L134.701 9.408H117.357V0.959999H147.309V8.768L137.069 16.704C140.355 16.9173 143.107 18.2187 145.325 20.608C147.587 22.9547 148.717 25.8773 148.717 29.376C148.717 33.216 147.224 36.5227 144.237 39.296C141.293 42.0267 137.347 43.392 132.397 43.392C127.747 43.392 123.928 42.112 120.941 39.552C117.955 36.9493 116.291 33.792 115.949 30.08L125.677 28.288C125.763 30.1653 126.403 31.7227 127.597 32.96C128.835 34.1973 130.413 34.816 132.333 34.816C134.211 34.816 135.704 34.2613 136.813 33.152C137.965 32.0427 138.541 30.6987 138.541 29.12C138.541 27.2853 137.944 25.8987 136.749 24.96C135.555 23.9787 134.147 23.488 132.525 23.488C131.245 23.488 130.008 23.808 128.813 24.448Z" fill="#ED6060"/>
                            </g>
                        </svg>
                    </div>
                    <div class="h4 text-primary mt-4 mb-1">
                        Visual Design &<br>
                        UX development
                    </div>
                    <div>
                        We create state-of-the-art visual web and mobile user interfaces. We can also design everything you need for DApp marketing: logos, splash screens, posters, booklets, presentations.
                    </div>

                </flex-col>
            </flex-row>
        </div>
    </section>
</template>

<script>
    export default {
        name: "WhatWeDo"
    }
</script>

<style scoped lang="scss">
    .item {
        width: 30%;
        svg {height: 2.2rem}
        @media (max-width: $screen-xs) {
            +.item { margin-top: 2.5rem; }
        }
    }

</style>
