<template>
  <div id="app">
    <MobileHeader v-if="isMobile"></MobileHeader>
    <Welcome></Welcome>
    <ContactBar v-if="!isMobile"></ContactBar>
    <WhatWeDo></WhatWeDo>
    <Experience></Experience>
    <Advantages></Advantages>
    <Contacts></Contacts>
    <Partners></Partners>
    <Footer/>
  </div>
</template>

<script>
import Welcome from './components/Welcome.vue'
import ContactBar from './components/ContactBar'
import WhatWeDo from "./components/WhatWeDo";
import Advantages from "./components/Advantages";
import Partners from "./components/Partners";
import Contacts from "./components/Contacts";
import MobileHeader from "./components/MobileHeader";
import Experience from "./components/Experience";
import Footer from "@/components/Footer";


export default {
  name: 'App',
  components: {
    Footer,
    Experience,
    MobileHeader,
    Contacts,
    Partners,
    Advantages,
    WhatWeDo,
    ContactBar,
    Welcome
  },

  data: function () {
    return {
      isMobile: window.innerWidth < 560,
    }
  },

  mounted() {
    this.track()
    window.onresize = () => {
      this.isMobile = window.innerWidth < 560;
    }
  },
  methods: {
    track() {
      this.$ga.page('/')
    }
  }
}
</script>

<style lang="scss">
  #app {
    @media (max-width: $screen-xs) {
      padding-top: $header-mobile-height;
    }
  }
</style>
